<template>
	<MobileAppWizardStep
		v-model="application"
		:steps="steps"
		:start-with-last="startWithLast"
		:step-name="stepName"
		@previous="previous"
		@next="next"
		@unvalidated="unvalidateSteps"
		@validated="validateSteps"
	/>
</template>

<script>
export default {
	name: 'MobileAppStepAppleAppStore',
	components: {
		MobileAppWizardStep: () => ({
			component: import('@/components/SuperAdmin/MobileApp/CreationSteps/MobileAppWizardStep')
		})
	},
	props: {
		value: {
			required: true,
			type: Object
		},
		startWithLast: {
			required: false,
			type: Boolean,
			default: false
		},
		stepName: {
			required: true,
			type: String
		}
	},
	data: function () {
		return {
			stepsValidated: false,
			steps: [
				{
					title: this.$t('mobileapp.creationSteps.ios.steps.1.steps.1.title', { email: 'admin@welyb.fr' }),
					href: () => 'https://appstoreconnect.apple.com'
				},
				{
					title: this.$t('mobileapp.creationSteps.ios.steps.1.steps.2.title')
				},
				{
					title: this.$t('mobileapp.creationSteps.ios.steps.1.steps.3.title')
				},
				{
					title: this.$t('mobileapp.creationSteps.ios.steps.1.steps.3.steps.2.title'),
					valueToCopy: () => this.application.title
				},
				{
					title: this.$t('mobileapp.creationSteps.ios.steps.1.steps.3.steps.1.title')
				},
				{
					title: this.$t('mobileapp.creationSteps.ios.steps.1.steps.3.steps.4.title'),
					valueToCopy: () => this.application.bundle_id
				},
				{
					title: this.$t('mobileapp.creationSteps.ios.steps.1.steps.3.steps.5.title')
				},
				{
					title: this.$t('mobileapp.creationSteps.ios.steps.1.steps.4.title')
				},
				{
					title: this.$t('mobileapp.creationSteps.ios.steps.1.steps.3.steps.3.title'),
					valueToCopy: () => this.application.subtitle
				},
				{
					title: this.$t('mobileapp.creationSteps.ios.steps.1.steps.5.title'),
					component: () => import('@/components/SuperAdmin/MobileApp/Details/MobileAppAppStoreID')
				}
			]
		}
	},
	computed: {
		application: {
			get: function () {
				return this.value
			},
			set: function (val) {
				this.$emit('input', val)
			}
		},
		validated: function () {
			return this.stepsValidated && typeof this.application.app_store_id === 'string' ? (this.application.app_store_id.trim().length > 0) : (this.application?.app_store_id > 0)
		}
	},
	watch: {
		application: {
			deep: true,
			immediate: true,
			handler: function () {
				this.validate()
			}
		},
		stepsValidated: {
			immediate: true,
			handler: function () {
				this.validate()
			}
		}
	},
	methods: {
		previous: function () {
			this.$emit('previous')
		},
		next: function () {
			this.$emit('next')
		},
		unvalidateSteps: function () {
			this.stepsValidated = false
		},
		validateSteps: function () {
			this.stepsValidated = true
		},
		validate: function () {
			if (this.validated) {
				this.$emit('validated')
			} else {
				this.$emit('unvalidated')
			}
		}
	}
}
</script>
